<template>
  <div class="center">
    <!--列表label-->
    <head-layout :head-title="this.$t('cip.plat.sync.dept.title.indexHeadTitle')"
                 :head-btn-options="headBtnOptions"
                 @head-remove="handleDelete"
                 @head-add="headAdd"></head-layout>
    <!--列表查询条件和头部按钮-->
    <grid-head-layout ref="gridHeadLayout"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout ref="gridLayOut"
                 :head-title="this.$t('cip.plat.sync.dept.title.indexHeadTitle')"
                 :table-options="option"
                 :table-data="data"
                 :table-loading="loading"
                 :data-total="page.total"
                 :grid-row-btn="gridRowBtn"
                 :page="page"
                 @gird-handle-select-click="selectionChange"
                 @grid-row-detail-click="rowView"
                 @page-current-change="onLoad"
                 @page-size-change="onLoad"
                 @tree-load='treeLoad'
                 @row-edit="rowEdit"
                 @row-del="rowDel">
    </grid-layout>
  </div>
</template>

<script>
import {getList, getLazyList, remove} from "@/api/sync/dept";
import {mapGetters} from "vuex";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

export default {
  components: {HeadLayout, GridLayout},
  data() {
    return {
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 1000,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        lazy: true,
        tree: true,
        rowKey: 'id',
        index: true,
        indexLabel: this.$t("cip.cmn.title.SerialNumber"),
        menuWidth: 120,
        linklabel: "title",
        column: [
          {
            label: this.$t('cip.plat.sync.dept.field.name'),
            prop: "name",
            align: "left",
            overHidden: true,
            width: 300,
          },
          {
            label: this.$t('cip.plat.sync.dept.field.code'),
            prop: "code",
            align: "left",
            overHidden: true,
            width: 120,
          },
          {
            label: this.$t('cip.plat.sync.dept.field.shortName'),
            prop: "shortName",
            align: "center",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.sync.dept.field.unity'),
            prop: "unity",
            align: "center",
            overHidden: true,
            width: 120,
          },
          {
            label: this.$t('cip.plat.sync.dept.field.deptRank'),
            prop: "deptRank",
            align: "center",
            overHidden: true,
            width: 120,
          },
          {
            label: this.$t('cip.plat.sync.dept.field.status'),
            prop: "status",
            type: "select",
            dataType: "number",
            align: "center",
            width: 120,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=dept_status",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
          },
        ]
      },
      data: [],
      pcode: "",
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    // permissionList() {
    //   return {
    //     addBtn: this.vaildData(this.permission.personalSharing_add, false),
    //     delBtn: this.vaildData(this.permission.personalSharing_delete, false),
    //     editBtn: this.vaildData(this.permission.personalSharing_edit, false),
    //   };
    // },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.code);
      });
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          label: this.$t('cip.plat.sync.dept.field.name'),
          prop: "name",
          span: 4,
          placeholder: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sync.dept.field.name'),
        },
        {
          label: this.$t('cip.plat.sync.dept.field.unity'),
          prop: "unity",
          span: 4,
          type: "select",
          clearable: false,
          dicData: [],
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=sinoma_dept_unity",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          placeholder: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.sync.dept.field.unity'),
        },
        {
          label: this.$t('cip.plat.sync.dept.field.status'),
          prop: "status",
          span: 4,
          type: "select",
          clearable: false,
          dataType: "number",
          dicData: [],
          placeholder: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.sync.dept.field.status'),
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=dept_status",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
        },
      ]
    },
    headBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push(
        {
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add",
          btnOptType: 'add',
        });
      buttonBtn.push(
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          btnOptType: 'remove',
        });
      return buttonBtn;
    },
    // 行按钮添加
    gridRowBtn() {
      let buttonBtn = [];
      buttonBtn.push(
        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
        });
      buttonBtn.push(
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-del",
        })
      return buttonBtn;
    },
  },
  methods: {
    // 列表查询
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 查询重置
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    headAdd() {
      this.$router.push({
        path: `/system/sync/dept/add`,
        query: {
          type: 'add',
        }
      })
    },
    rowEdit(row) {
      this.$router.push({
        path: `/system/sync/dept/edit`,
        query: {
          id: row.id,
          type: 'edit',
        }
      })
    },
    rowView(row) {
      this.$router.push({
        path: `/system/sync/dept/view`,
        query: {
          id: row.id,
          type: 'view',
        }
      })
    },
    rowDel(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          remove(row.code);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
          this.onLoad(this.page);
        });
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.chooseAtLeastOne'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          remove(this.ids).then((res) => {
            if (res.data.code == 200) {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess")
              });
            }
          })
        })
    },
    onLoad(page, params = {}) {
      console.log(this.pcode,"123")
      this.page = page;
      this.loading = true;
      if (params.name || params.unity || params.status) {
        getList(page.currentPage, page.pageSize, params).then(res => {
          const data = res.data.data;
          // this.page.total = data.total;
          this.data = data.records;
          this.loading = false;
          this.$refs.gridLayOut.grid.refreshTable()
          this.selectionList = [];
        });
      }else {
        getLazyList(page.currentPage, page.pageSize, this.pcode, Object.assign(params, this.$refs.gridHeadLayout.searchForm)).then(res => {
          const data = res.data.data;
          // this.page.total = data.total;
          this.data = data.records;
          this.loading = false;
          this.$refs.gridLayOut.grid.refreshTable()
          this.selectionList = [];
        });
      }
    },
    treeLoad(tree, treeNode, resolve) {
      const pcode = tree.code;
      getLazyList(1,1000,pcode,{}).then(res => {
        resolve(res.data.data.records);
      });
    },
  },
  // 初始化数据
  mounted() {
    this.onLoad(this.page, {});
  },
};
</script>
